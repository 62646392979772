.portfolio-work {
	padding: 80px 0;

	.block {
		.portfolio-menu {
			text-align: center;

			.btn-group {
				margin-bottom: 40px;

				label {
					display: inline-block;
					border: 1px solid $border-color;
					padding: 8px 25px;
					cursor: pointer;
					font-size: 15px;
					color: #333333;
					outline: 0;
					background: $light;
					margin: 2px;
					border-radius: 0;
					@include transition (all, 0.3s, ease);
				}
			}
		}
	}
}

.portfolio {
	&-item {
		position: relative;
		padding: 0;

		img {
			width: 100%;
			height: auto;
		}

		&:hover {
			.portfolio-hover {
				visibility: visible;
				opacity: 1;
			}

			.portfolio-content {
				transform: translateY(-50%);
			}
		}
	}

	&-hover {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: rgba(60, 55, 55, 0.5);
		visibility: hidden;
		opacity: 0;
		transition: .3s ease;
	}

	&-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-40%);
		text-align: center;
		padding: 20px;
		transition: inherit;

		* {
			color: $light;
		}

		a {
			display: block;
			transition: .2s ease;

			i {
				font-size: 30px;
			}

			&.h3 {
				margin-top: 0;
			}
		}
	}
}




// Portfolio Single Page
.portfolio-single-page {
	.project-details {
		h4 {
			margin-bottom: 20px;
			padding-bottom: 10px;
			border-bottom: 2px dashed $border-color;
		}

		span {
			color: #838383;
			width: 180px;
			display: inline-block;
		}

		strong {
			color: #313131;
			font-weight: normal;
		}

		ul {
			li {
				margin-bottom: 10px;
			}
		}
	}
}