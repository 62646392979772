.testimonial{
	padding: 100px 0;
	.block{
		h2{
			line-height: 27px;
			color: #5C5C5C;
			padding-top: 110px;
		}
		p{
			padding-top: 50px;
			color: #7B7B7B;
		}
	}
	.counter-box {
		li {
			width: 50%;
			float: left;
			text-align: center;
			margin: 30px 0 30px;
		}
	}
	
	.testimonial-carousel{
		border: 1px solid $border-color;
		padding: 24px;
		i {
			font-size:35px;
		}
		p{
			font-family:$secondary-font;
			line-height: 28px;
			padding-bottom: 20px;
		}
		.user{
			img{
				padding-bottom: 0px;
				border-radius: 500px;
				width: 80px;
				display:inline-block;
			}
			p{
				font-family:$primary-font;
				padding-bottom: 0;
				margin-top:6px;
				font-size: 12px;
				line-height: 20px;
				span{
					display: block;
					color: #353241;
					font-weight: 600;
				}
			}
		}
		.owl-carousel .owl-pagination div{
		    border: 1px solid #1D1D1D;
		    border-radius: 500px;
		    display: inline-block;
		    height: 10px;
		    margin-right: 15px;
		    width: 10px;
		}
		.owl-carousel .owl-pagination div.active{
		    background: #5C5C5C;
		    font-size: 30px;
		    display: inline-block;
		    border: 0px;
		}
	}
}

.counter-box {
	i {
		font-size: 35px;
	}
	h4 {
		font-size: 30px;
		font-weight: bold;
	}
	span {
		color: #555;
	}
		
}