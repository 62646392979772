.footer{
  background: #F5F5F5;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 55px;
  p{
    font-size: 13px;
    line-height: 25px;
    color: #919191;   
  }
  a{
    color: #595959;
  }
  .footer-manu{
    padding-bottom: 25px;
    ul{
      margin: 0px;
      padding: 0px;
      li{
        display: inline-block;
        padding: 0px 20px;
        a{
          display: inline-block;
          color: #494949;
          &:hover{
            color: #000;
          }
        }
      }
    }
  }
  .copyright{
    a{
      font-weight: 600;
    }
  }
}
