.bg-1 {
	background-image: url("../images/call-to-action-bg.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

}

.bg-2 {
	background: url("../images/featue-bg.jpg");
	background-size: cover;
	background-attachment:fixed;
}
