.slider{
  background: url("../images/slider-bg.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 10% 0%;
  padding:200px 0 280px 0;
  position: relative;
  @extend .overly;
  .block{
    color: #E3E3E4;
    text-align:center;
    h1{
      font-weight: 100;
      font-size: 45px;
      line-height: 60px;
      letter-spacing: 10px;
      padding-bottom: 15px;
      text-transform:uppercase;
    }
    p{
      margin-bottom:30px;
      color:#b9b9b9;
      font-size: 18px;
      line-height: 27px;
      font-weight: 300;
    }
  }
}
